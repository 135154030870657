.pricingCard {
    width: 300px; /* Set a fixed width */
    height: 400px; /* Set a fixed height */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow */
    margin: 16px; /* Ensure fixed spacing between cards */
  }
  
  .customSwitch {
    --gestalt-switch-height: 16px;
    --gestalt-switch-width: 28px;
  }
  
  .customSwitch input[type="checkbox"]:checked + div {
    background-color: blue;
  }

  
  .nav-bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    /* display: flex; */
    /* justify-content: space-between; */
    padding: 1rem rem;
    background-color: #000000;
    color: #ffffff;
    opacity: 40%;
  }
  
  .nav-bar ul {
    list-style: none;
    display: flex;
  }
  
  .nav-bar ul li {
    padding-left: 1rem;
  }
  
  .nav-bar a {
    text-decoration: none;
    color: #ffffff;
  }
  .nav-bar a:hover {
    text-decoration: underline;
  }
  
  .footer {
    display: flex;
    padding: 40px;
    font-size: 14px;
    color: #000000;
    justify-content: center;
  }
  
  .red {
    color: red;
  }
  
  .blue {
    color: blue;
  }
  